import type { LocalizedString } from '../utils/localization';

/**
 * All the analytics actions that we track in Case Creation flow
 */
export enum CaseAssistAnalyticsActions {
  CASE_STARTED = 'CASE_STARTED',
  CASE_COVEO_SUGGESTIONS_SHOWN = 'CASE_COVEO_SUGGESTIONS_SHOWN',
  CASE_COVEO_SUGGESTIONS_EMPTY = 'CASE_COVEO_SUGGESTIONS_EMPTY',
  CASE_COVEO_SUGGESTION_OPEN = 'CASE_COVEO_SUGGESTION_OPEN',
  CASE_DOCSGPT_SUGGESTIONS_SHOWN = 'CASE_DOCSGPT_SUGGESTIONS_SHOWN',
  CASE_FINAL_PAGE = 'CASE_FINAL_PAGE',
  CASE_CREATED = 'CASE_CREATED',
  CASE_SOLVED = 'CASE_SOLVED',
  CASE_ABANDONED = 'CASE_ABANDONED',
}

export enum ResultsShown {
  COVEO = 'Coveo',
  DOCSGPT = 'DocsGPT',
}

export enum CustomEventType {
  FEEDBACK_RESPONSE = 'feedback_response',
  DOCSGPT_SUGGESTION_CLICK = 'docsgpt_suggestion_click',
  DOCUMENT_VIEW = 'document_view',
  DOCS_AI = 'docs_ai',
}

export enum FeedbackResponse {
  YES = 'Yes',
  NO = 'No',
}

export const DeliveryOptionsWithVersionSelections = [ 'Automation Suite', 'Standalone' ];

/**
 * this is the list of document/links we show in the Recommended suggestions,
 * if coveo cannot find any relevant suggestions
 */
export const defaultSuggestions: Array<{
  title: LocalizedString;
  description: LocalizedString;
  link: string;
}> = [
  {
    title: {
      textKey: 'support_form_create_default_suggestion_title_product_download',
      fallbackText: 'Product Downloads',
    },
    description: {
      textKey:
        'support_form_create_default_suggestion_description_product_download',
      fallbackText: 'Download the latest standalone software and connectors',
    },
    link: 'https://customerportal.uipath.com/product-downloads',
  },
  {
    title: {
      textKey:
        'support_form_create_default_suggestion_title_product_documentation',
      fallbackText: 'Product Documentation',
    },
    description: {
      textKey:
        'support_form_create_default_suggestion_description_documentation',
      fallbackText:
        'Guides, how-tos, and release notes for all UiPath products.',
    },
    link: 'https://docs.uipath.com/',
  },
  {
    title: {
      textKey: 'support_form_create_default_suggestion_title_guide',
      fallbackText: 'Installation and Upgrade Guide',
    },
    description: {
      textKey: 'support_form_create_default_suggestion_description_guide',
      fallbackText:
        'Requirements, best practices, upgrade, and installation guides.',
    },
    link: 'https://docs.uipath.com/installation-and-upgrade',
  },
  {
    title: {
      textKey: 'support_form_create_default_suggestion_title_forum',
      fallbackText: 'UiPath Forum',
    },
    description: {
      textKey: 'support_form_create_default_suggestion_description_forum',
      fallbackText:
        'Ask questions on the UiPath community to get the fastest response',
    },
    link: 'https://forum.uipath.com/',
  },
];

// Translations
import { UserRole } from '@customer-portal/constants';

import {
  COLLAB,
  HAPO,
  LICENSE,
  PREMIUMCARE,
  SEND_INVITATION_AND_PERMISSION_EDITING,
  SUPPORT,
} from './roles.constants';

const licenseLabelVal = {
  keyText: 'user_permissions_licence_label',
  fallbackText: 'License',
};
const licenseAndRequestQuoteLabelVal = {
  keyText: 'user_permissions_licence_rfq_label',
  fallbackText: 'License & Request Quote',
};

// Short description for a given Type of Permission
export const permissionInfo = (type: string, rfqEnabled: boolean) => {
  let viewPermissionInfo: { keyText: string; fallbackText: string } = {
    keyText: '',
    fallbackText: '',
  };
  let editPermissionInfo: { keyText: string; fallbackText: string } = {
    keyText: '',
    fallbackText: '',
  };

  switch (type) {
    case LICENSE:
      if (rfqEnabled) {
        viewPermissionInfo = {
          keyText: 'user_permissions_info_licence_rfq_view_text',
          fallbackText: 'View: Can view Licenses and Product Catalog',
        };
        editPermissionInfo = {
          keyText: 'user_permissions_info_licence_rfq_edit_text',
          fallbackText:
            'Edit: Can add display name & notes to Licenses, and Request Quote for more products',
        };
      } else {
        viewPermissionInfo = {
          keyText: 'user_permissions_info_licence_view_text',
          fallbackText: 'View: Can view Licenses',
        };
        editPermissionInfo = {
          keyText: 'user_permissions_info_licence_edit_text',
          fallbackText: 'Edit: Can add display name & notes to Licenses',
        };
      }
      break;
    case SUPPORT:
      viewPermissionInfo = {
        keyText: 'user_permissions_info_support_view_text',
        fallbackText: 'View: Can view open/closed support requests',
      };
      editPermissionInfo = {
        keyText: 'user_permissions_info_support_edit_text',
        fallbackText: 'Edit: Can manage support requests',
      };
      break;
    case PREMIUMCARE:
      viewPermissionInfo = {
        keyText: 'user_permissions_info_premium_care_view_text',
        fallbackText: 'View: Can view open/closed support requests and support dashboard',
      };
      editPermissionInfo = {
        keyText: 'user_permissions_info_premium_care_edit_text',
        fallbackText: 'Edit: Can manage support requests',
      };
      break;
    case COLLAB:
      viewPermissionInfo = {
        keyText: 'user_permissions_info_collab_view_text',
        fallbackText: 'View: Can view and download documents',
      };
      editPermissionInfo = {
        keyText: 'user_permissions_info_collab_edit_text',
        fallbackText: 'Edit: Can create folders and upload/delete documents',
      };
      break;
    case HAPO:
      viewPermissionInfo = {
        keyText: 'user_permissions_info_hapo_view_text',
        fallbackText:
          'View: Can view License Management & Past Requests',
      };
      editPermissionInfo = {
        keyText: 'user_permissions_info_hapo_edit_text',
        fallbackText: 'Edit: Can request licenses on demand',
      };
      break;
    case SEND_INVITATION_AND_PERMISSION_EDITING:
      viewPermissionInfo = {
        keyText: 'user_permissions_info_invitation_permissions_view_text',
        fallbackText:
          'Invitation: Can send invitations to join Customer Portal',
      };
      editPermissionInfo = {
        keyText: 'user_permissions_info_invitation_permissions_edit_text',
        fallbackText: 'Permissions: Can edit user permissions',
      };
      break;
    default:
      break;
  }

  return [ viewPermissionInfo, editPermissionInfo ];
};

export const standardPermissionValues = [
  {
    name: 'read',
    label: {
      keyText: 'user_permissions_can_view_title',
      fallbackText: 'Can View',
    },
    value: true,
  },
  {
    name: 'write',
    label: {
      keyText: 'user_permissions_can_edit_title',
      fallbackText: 'Can Edit',
    },
    value: true,
  },
];

export const addUserPermissionsPremium = (
  rfqEnabled: boolean,
  hapoEnabled: boolean,
  utoEnabled: boolean,
  isCollabSpaceEnabled: boolean
) => addPermissions(rfqEnabled, true, hapoEnabled, utoEnabled, isCollabSpaceEnabled);

export const addUserPermissions = (
  rfqEnabled: boolean,
  hapoEnabled: boolean,
  utoEnabled: boolean,
  isCollabSpaceEnabled: boolean
) => addPermissions(rfqEnabled, false, hapoEnabled, utoEnabled, isCollabSpaceEnabled);

// Get user permissions list depending of if the account is permium or not
const addPermissions = (
  rfqEnabled: boolean,
  isPremium: boolean,
  hapoEnabled: boolean,
  utoEnabled: boolean,
  isCollabSpaceEnabled: boolean
) => {
  const licenseLabel = rfqEnabled
    ? licenseAndRequestQuoteLabelVal
    : licenseLabelVal;

  const permissions: Array<{
    type: string;
    label: {
      keyText: string;
      fallbackText: string;
    };
    permissionItems: Array<{
      id: string;
      label: {
        keyText: string;
        fallbackText: string;
      };
      permissions: Array<{
        name: string;
        label: {
          keyText: string;
          fallbackText: string;
        };
        value: boolean;
      }>;
    }>;
  }> = [
    {
      type: UserRole.CP_USER,
      label: {
        keyText: 'user_profile_company_and_permissions_regular_user_label',
        fallbackText: 'Regular User',
      },
      permissionItems: [
        {
          id: LICENSE,
          label: licenseLabel,
          permissions: [
            {
              name: 'read',
              label: {
                keyText: 'user_permissions_can_view_title',
                fallbackText: 'Can View',
              },
              value: true,
            },
            {
              name: 'write',
              label: {
                keyText: 'user_permissions_can_edit_title',
                fallbackText: 'Can Edit',
              },
              value: false,
            },
          ],
        },
        {
          id: SUPPORT,
          label: {
            keyText: 'user_permissions_support_label',
            fallbackText: 'Support',
          },
          permissions: standardPermissionValues,
        },
        ...(isCollabSpaceEnabled ? [
          {
            id: COLLAB,
            label: {
              keyText: 'user_permissions_collab_label',
              fallbackText: 'Collaboration Space',
            },
            permissions: standardPermissionValues,
          },
        ] : []),
      ],
    },
    {
      type: UserRole.CP_ADMIN,
      label: {
        keyText: 'user_profile_company_and_permissions_admin_role_label',
        fallbackText: 'Admin',
      },
      permissionItems: [],
    },
  ];

  // Add Permium Care Permissions
  if (isPremium) {
    permissions[0].permissionItems.splice(2, 0, {
      id: PREMIUMCARE,
      label: {
        keyText: 'user_permissions_premium_care_label',
        fallbackText: 'Preventive Care',
      },
      permissions: [
        {
          name: 'read',
          label: {
            keyText: 'user_permissions_can_view_title',
            fallbackText: 'Can View',
          },
          value: false,
        },
        {
          name: 'write',
          label: {
            keyText: 'user_permissions_can_edit_title',
            fallbackText: 'Can Edit',
          },
          value: false,
        },
      ],
    });
  }

  // Add HAPO Permissions
  if (hapoEnabled || utoEnabled) {
    permissions[0].permissionItems.push({
      id: HAPO,
      label: {
        keyText: 'user_permissions_hapo_label',
        fallbackText: 'License Management',
      },
      permissions: standardPermissionValues,
    });
  }
  return permissions;
};

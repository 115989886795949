import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

// styles
import * as styled from '../../assets/css/Support/SupportKB/CustomerPortalSupportKBDetails';
import Button from '../../components/Button/Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import ScrollToTop from '../../components/CustomerPortal-ScrollToTop';
import SupportKBLinkOut from '../../components/support_kb/link_out/SupportKBLinkOut';
// Constants
import { CustomEventType } from '../../constants/caseAssist.constants';
import KB_ACTION from '../../constants/kbActions.constants';
import { SUPPORT_KNOWLEDGE_READ } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import {
  useTrackPageViewEvent,
  useTriggerTrackEventWithStateData,
} from '../../lib/AppInsights/AppInsights';
// Util Functions
import { kbDataReducer } from '../../reducers/kb_reducers';
import { StoreContext } from '../../store';
import { logCustomEvent } from '../../utils/caseAssist';
// components
import Container from './../../components/CustomerPortal-New-Container';
import { SUPPORT_KB_URL } from './../../constants/network.constants';

const CustomerPortalSupportKBDetails = (props: any) => {
  const { getAccessToken } = useAuth();
  const { state } = useContext(StoreContext);

  // Translate method
  const { t } = useTranslation('common');
  const createdLabel = t('support_kb_details_page_created_label', 'Created:');
  const lastUpdatedLabel = t(
    'support_kb_details_page_last_updated_label',
    'Last updated:'
  );
  const categoryLabel = t(
    'support_kb_details_page_category_label',
    'Category:'
  );
  const deploymentTypeLabel = t(
    'support_kb_details_page_deployment_type_label',
    'Deployment Type:'
  );
  const studioVersionLabel = t(
    'support_kb_details_page_studio_version_label',
    'Studio Version:'
  );
  const orchestratorVersionLabel = t(
    'support_kb_details_page_orchestrator_version_label',
    'Orchestrator Version:'
  );
  const questionLabel = t(
    'support_kb_details_page_question_label',
    'Question:'
  );
  const screenName = 'Support KB Article Details';

  /* Default constants */
  const { articleNum } = useParams<any>();
  const history = useHistory();
  const [ back, setBack ] = useState(false);

  const initialArticleDataState = {
    error: undefined,
    isLoading: true,
    data: {},
  };

  const [ article, dispatchArticle ]: [any, any] = useReducer(
    kbDataReducer,
    initialArticleDataState
  );

  /* Lifecycle */
  useTrackPageViewEvent(screenName);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView(screenName);
    getArticleData();
  }, []);

  useEffect(() => {
    if (back) {
      history.goBack();
    }
  }, [ back ]);

  const getDate = (timeStamp: string) => {
    const date = new Date(timeStamp);
    return date.toDateString();
  };

  const getArticleData = async () => {
    try {
      const articleData = await axios.get(`${SUPPORT_KB_URL}/article`, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-auth-type': getAuthType(),
        },
        params: { articleNumber: articleNum },
      });

      if (
        articleData.status === 200 &&
        articleData.data?.data
      ) {
        const {
          category, id: articleId, title, articleNumber,
        } = articleData.data.data;
        dispatchArticle({
          type: KB_ACTION.FETCH_SUCCESS,
          data: articleData.data.data,
        });
        const documentData = {
          'article_id': articleId,
          'article_number': articleNumber,
          'article_category': category,
          'article_title': title,
          'article_url': window.location.href.toString(),
        };
        logCustomEvent(
          CustomEventType.DOCUMENT_VIEW,
          documentData,
          state.companyId,
          await getAccessToken(),
        );
        triggerTrackEventWithStateData(SUPPORT_KNOWLEDGE_READ, {
          ArticleId: articleId,
          ArticleCategory: category,
          ArticleTitle: title,
          ArticleNumber: articleNumber,
        });
      } else {
        dispatchArticle({
          type: KB_ACTION.FETCH_FAIL,
          error: `Unable to resolve products`,
        });
      }
    } catch (e) {
      dispatchArticle({
        type: KB_ACTION.FETCH_FAIL,
        error: `Unable to resolve products`,
      });
      console.log(e.toString());
    }
  };

  if (article.isLoading) {
    return (
      <styled.notificationsLoader>
        <div data-testid="CustomerPortalSupportKBDetails__Loading">
          <CustomerPortalLoader />
        </div>
      </styled.notificationsLoader>
    );
  }
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>
          {t(
            'support_kb_details_page_title',
            'Support Knowledge Base Details | Customer Portal'
          )}
        </title>
      </Helmet>

      <styled.PageWrapper>
        <Container cssClass="CustomerPortalPage__container">
          <div
            className="heroBackButton"
            onClick={() => {
              setBack(true);
            }}
          >
            {t('support_kb_page_go_back_btn', 'Go Back')}
          </div>

          {article.data.title && (
            <styled.heroTitleWrapper>
              <styled.heroTitle>{article.data.title}</styled.heroTitle>
            </styled.heroTitleWrapper>
          )}

          {article.error == null && (
            <div className="supportDetailsInfo">
              <styled.supportDetailStatsDiv data-testid="supportDetailStatsDiv">
                <styled.supportDetailsInfoDiv>
                  <styled.supportDetailStatsLabel>
                    {createdLabel}
                  </styled.supportDetailStatsLabel>
                  <styled.supportDetailStats>
                    {getDate(article.data.articleCreatedDate)}
                  </styled.supportDetailStats>
                </styled.supportDetailsInfoDiv>
                <styled.supportDetailsInfoDiv>
                  <styled.supportDetailStatsLabel>
                    {lastUpdatedLabel}
                  </styled.supportDetailStatsLabel>
                  <styled.supportDetailStats>
                    {getDate(article.data.lastModifiedDate)}
                  </styled.supportDetailStats>
                </styled.supportDetailsInfoDiv>
                <styled.supportDetailsInfoDiv>
                  <styled.supportDetailStats>
                    {article.data.totalViewCount}
                    {' '}
Views
                  </styled.supportDetailStats>
                </styled.supportDetailsInfoDiv>
              </styled.supportDetailStatsDiv>

              <styled.supportDetailsBanner>
                {article.data.category && (
                  <styled.supportDetailsInfoDiv>
                    <styled.supportDetailsInfoLabel>
                      {categoryLabel}
                    </styled.supportDetailsInfoLabel>
                    <styled.supportDetailsInfo data-testid="category">
                      {article.data.category}
                    </styled.supportDetailsInfo>
                  </styled.supportDetailsInfoDiv>
                )}
                {article.data.deploymentType && (
                  <styled.supportDetailsInfoDiv>
                    <styled.supportDetailsInfoLabel>
                      {deploymentTypeLabel}
                    </styled.supportDetailsInfoLabel>
                    <styled.supportDetailsInfo data-testid="deploymentType">
                      {article.data.deploymentType.replace(/;/g, ', ')}
                    </styled.supportDetailsInfo>
                  </styled.supportDetailsInfoDiv>
                )}
                {article.data.studioVersion && (
                  <styled.supportDetailsInfoDiv>
                    <styled.supportDetailsInfoLabel>
                      {studioVersionLabel}
                    </styled.supportDetailsInfoLabel>
                    <styled.supportDetailsInfo data-testid="studioVersion">
                      {article.data.studioVersion.replace(/;/g, ', ')}
                    </styled.supportDetailsInfo>
                  </styled.supportDetailsInfoDiv>
                )}
                {article.data.orchestratorVersion && (
                  <styled.supportDetailsInfoDiv>
                    <styled.supportDetailsInfoLabel>
                      {orchestratorVersionLabel}
                    </styled.supportDetailsInfoLabel>
                    <styled.supportDetailsInfo data-testid="orchestratorVersion">
                      {article.data.orchestratorVersion.replace(/;/g, ', ')}
                    </styled.supportDetailsInfo>
                  </styled.supportDetailsInfoDiv>
                )}
                {article.data.question && (
                  <styled.supportDetailsInfoDiv>
                    <styled.supportDetailsInfoLabel>
                      {questionLabel}
                    </styled.supportDetailsInfoLabel>
                    <styled.supportDetailsInfo data-testid="question">
                      <div
                        className="question"
                        dangerouslySetInnerHTML={{ __html: article.data.question }}
                      />
                    </styled.supportDetailsInfo>
                  </styled.supportDetailsInfoDiv>
                )}
              </styled.supportDetailsBanner>

              <styled.supportDetailsBanner>
                {article.data.cpResolution && (
                  <styled.supportTicketDescriptionWrapper data-testid="resolution">
                    <styled.supportDetailsBannerLabel>
                        Resolution
                    </styled.supportDetailsBannerLabel>
                    <div
                      className="resolution"
                      dangerouslySetInnerHTML={{ __html: article.data.cpResolution }}
                    />
                  </styled.supportTicketDescriptionWrapper>
                )}
              </styled.supportDetailsBanner>
            </div>
          )}

          {article.error && (
            <styled.noResultsTest>
              {t(
                'support_kb_details_page_no_result_text',
                'We could not find the Article details'
              )}
              <br />
              <br />
              <Button
                text="Back"
                onClick={() => setBack(true)} />
            </styled.noResultsTest>
          )}
          <SupportKBLinkOut screenName={screenName} />
        </Container>
      </styled.PageWrapper>
    </>
  );

};

export default CustomerPortalSupportKBDetails;

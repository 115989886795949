export const AvatarWhiteListedMimeTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const Questionnaire360WhiteListedMimeTypes = [
  'text/csv',
  'application/zip',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-compress',
  'application/x-compressed',
  'application/x-zip-compressed',
  'multipart/x-zip',
];

export const SupportWhiteListedMimeTypes = [
  ...AvatarWhiteListedMimeTypes,
  'image/bmp',
  'application/zip',
  'application/x-compress',
  'application/x-compressed',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'application/x-7z-compressed',
  'application/x-bzip',
  'application/x-bzip2',
  'application/gzip',
  'application/vnd.rar',
  'text/plain',
  'text/csv',
  'text/rtf',
  'audio/mpeg',
  'audio/ogg',
  'video/quicktime',
  'video/mpeg',
  'video/mp4',
  'application/rtf',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const CollabWhiteListedMimeTypes = [
  ...SupportWhiteListedMimeTypes,
  'video/3gpp2',
  'video/3gpp',
  'video/x-msvideo',
  'image/gif',
  'video/h261',
  'video/h263',
  'video/h264',
  'image/x-icon',
  'image/jpeg',
  'image/x-citrix-jpeg',
  'video/x-m4v',
  'video/mp4',
  'audio/mp4',
  'application/vnd.ms-project',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'video/ogg',
  'application/onenote',
  'image/png',
  'image/x-citrix-png',
  'image/x-png',
  'application/vnd.ms-powerpoint.template.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.ms-powerpoint.addin.macroenabled.12',
  'image/x-portable-pixmap',
  'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
  'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  'application/x-rar-compressed',
  'text/richtext',
  'application/vnd.ms-powerpoint.slide.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.slide',
  'application/vnd.visio',
  'application/vnd.visio2013',
  'audio/x-wav',
  'audio/webm',
  'video/webm',
  'video/x-ms-wm',
  'audio/x-ms-wma',
  'video/x-ms-wmv',
  'application/x-mswrite',
  'application/vnd.hzn-3d-crossword',
  'application/vnd.ms-excel.addin.macroenabled.12',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel.template.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'text/yaml',
];

export const KBWhiteListedMimeTypes = [
  ...CollabWhiteListedMimeTypes,
];

export const SupportEmailReplyWhiteListedMimeTypes = [
  'text/plain',
  'text/csv',
  'application/rtf',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/x-citrix-jpeg',
  'image/png',
  'image/x-citrix-png',
  'image/x-png',
  'application/zip',
  'application/x-zip-compressed',
  'application/vnd.rar',
  'application/x-rar-compressed',
  'application/x-7z-compressed',
  'audio/mp3',
  'audio/ogg',
  'video/mpeg',
  'video/quicktime',
];

export const SupportLargeFileAttachmentWhiteListedMimeTypes = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/jpeg',
  'text/plain',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/zip',
  'application/xml',
  'application/xml',
  'application/xaml+xml',
  'application/x-tar',
  'application/gzip',
  'application/x-bzip2',
  'application/json',
];

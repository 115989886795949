import { DocsGptResponseSource } from '@customer-portal/constants';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import {
  CaseAssistAnalyticsActions,
  CustomEventType,
  FeedbackResponse,
} from '../../../constants/caseAssist.constants';
import { useAuth } from '../../../contexts/auth';
import type { DocsGptDeflectionProps } from '../../../interfaces/support.interface';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import {
  logCaseAssistAnalyticsEvent,
  logCustomEvent,
  logPublicCustomEvent,
} from '../../../utils/caseAssist';
import ApolloIcon from '../../ApolloIcon';
import TypewriterComponent from '../../TypewriterComponent';
import SupportCaseDeflectionModal from '../CaseCreation/SupportCaseDeflection-Modal';

const LoaderContainer = styled.div`
  font-size: 1.4rem;
  line-height: 1.7;
  display: flex;
  .LoaderText {
    margin-left: 8px;
  }
`;

const Title = styled.h6`
  color: ${p => p.theme.palette.ink[650]};
  &-Spacing {
    margin-bottom: -20px;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.7;
`;

const DeflectionContainer = styled.div<{ doneStreamedText?: boolean }>`
  .deflection-section {
    margin-bottom: 14px;
  }

  .deflection-response {
    overflow-y: auto;
    max-height: 50vh;
    padding-right: 20px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid ${p => p.theme.palette.grey[100]};
      background-color: rgba(0, 0, 0, .5);
    }
    ${(p) => p.doneStreamedText && `
      background: radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom;
      background-repeat: no-repeat;
      background-size: 100% 14px;
      background-attachment: scroll;
    `}
  }

  .feedback-response-button {
    margin-left: 8px !important;
  }
`;

const DocsGptDeflection = (props: DocsGptDeflectionProps) => {
  const { t } = useTranslation('common');
  const baseClass = 'SupportCaseBody';
  const {
    engine,
    caseAssistState,
    formData,
    gptResponse,
    isGptResponseLoading,
    receivedFeedbackResponse,
    setReceivedFeedbackResponse,
    isAnswerHelpful,
    setIsAnswerHelpful,
    resultsShownFirst,
    doneStreamedText,
    setDoneStreamedText,
    inSearchPage,
    query,
    selectedFilters,
  } = props;
  const isPublic = props.isPublic ?? false;
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const onBtnClick = async (feedbackResponse: FeedbackResponse) => {
    setReceivedFeedbackResponse(true);
    setIsAnswerHelpful(feedbackResponse === FeedbackResponse.YES);

    let caseData;
    if (formData) {
      caseData = {
        subject: formData.summary,
        description: formData.description,
        deploymentType: formData.deploymentType.value,
        product: formData.product.value,
        errorMessage: formData.errorMessage,
      };
    }

    const coveoData = {
      'docsgpt_response': gptResponse,
      'feedback_response': feedbackResponse,
      'source': inSearchPage ? DocsGptResponseSource.SEARCH : DocsGptResponseSource.CASE_CREATION,
      ...(!inSearchPage && { 'case_data': caseData }),
    };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
      );
    } else {
      logCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
        state.companyId,
        await getAccessToken(),
      );
    }
    triggerTrackEventWithStateData(
      feedbackResponse === FeedbackResponse.YES ? 'GPT.ResponseHelpful' : 'GPT.ResponseNotHelpful',
      {
        'DocsGPTResponse': gptResponse,
        'Source': inSearchPage ? DocsGptResponseSource.SEARCH : DocsGptResponseSource.CASE_CREATION,
        ...(!inSearchPage && { 'CaseData': caseData }),
      }
    );
  };

  const onSourceClicked = async (source: { title: string; url: string }) => {
    const coveoData = {
      'docsgpt_article_clicked': source,
      'source': inSearchPage ? DocsGptResponseSource.SEARCH : DocsGptResponseSource.CASE_CREATION,
    };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.DOCSGPT_SUGGESTION_CLICK,
        coveoData,
      );
    } else {
      logCustomEvent(
        CustomEventType.DOCSGPT_SUGGESTION_CLICK,
        coveoData,
        state.companyId,
        await getAccessToken(),
      );
    }
  };

  const logGptResponseShown = async () => {
    const coveoData = {
      'question': query,
      'delivery_option_filters': selectedFilters?.deliveryOption,
      'version_filters': selectedFilters?.version,
      'product_filters': selectedFilters?.product,
      'docsgpt_response': gptResponse,
      'source': DocsGptResponseSource.SEARCH,
    };
    logCustomEvent(
      CustomEventType.DOCS_AI,
      coveoData,
      state.companyId,
      await getAccessToken(),
    );
  };

  useEffect(() => {
    if (doneStreamedText) {
      if (!inSearchPage && engine) {
        const data = {
          Response: gptResponse.text,
          Articles: gptResponse.sources,
          ResultsShownFirst: resultsShownFirst,
        };
        logCaseAssistAnalyticsEvent(
          engine,
          CaseAssistAnalyticsActions.CASE_DOCSGPT_SUGGESTIONS_SHOWN,
          JSON.stringify(data)
        );
      } else {
        logGptResponseShown();
      }
    }
  }, [ doneStreamedText ]);

  return (
    <div>
      {isGptResponseLoading ? (
        inSearchPage ? (
          <LoaderContainer>
            <CircularProgress
              size={18}
              className='loader' />
            <div className='LoaderText'>
              {t('support_form_gpt_gathering_information',
                'Gathering Information')}
            </div>
          </LoaderContainer>
        ) : (
          <div>
            <Title>
              {t('support_form_generating_gpt_answer_text',
                'Generating an answer to your inquiry...')}
            </Title>
            <ApolloIcon
              className='loadingIcon'
              icon="settings"
              fontSize='inherit'
            />
          </div>
        )
      ) : (!receivedFeedbackResponse && gptResponse.text) ||
        (receivedFeedbackResponse && isAnswerHelpful) ||
        (receivedFeedbackResponse && !isAnswerHelpful && inSearchPage) ?
        (
          <DeflectionContainer>
            <div className='deflection-response'>
              {!inSearchPage && (
                <Title className="deflection-section">
                  {t(
                    'support_form_gpt_response_title',
                    'Hello! I found some suggestions for your issue:'
                  )}
                </Title>
              )}
              <TypewriterComponent
                text={gptResponse.text}
                completeMessage={gptResponse.text}
                completionCallback={() => {}}
                completedTying={() => setDoneStreamedText(true)}
                showCompleteMessage={doneStreamedText}
                speed={3}
              />
            </div>
            <br />
            {doneStreamedText && (
              <div>
                {gptResponse.sources.length !== 0 && (
                  <div>
                    <Text>
                      <b>{t('support_form_more_information', 'More information:')}</b>
                    </Text>
                    <Text className='deflection-section'>
                      {gptResponse.sources.map((source: any) => <li>
                        <a
                          href={source.url}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => onSourceClicked(source)}>
                          {source.title}
                        </a>
                      </li>)}
                    </Text>
                  </div>
                )}
                {receivedFeedbackResponse ? (
                  isAnswerHelpful ? (
                    <div>
                      <p>{t('support_form_answer_is_helpful', 'Thank you for your feedback. We\'re glad to have assisted you.')}</p>
                      <br />
                    </div>
                  ) : (
                    <div>
                      <p>{t('support_form_answer_is_not_helpful_2', 'Thanks for the feedback; adding additional details will allow us to give a more accurate answer to your inquiry.')}</p>
                      <br />
                    </div>
                  )
                ) : (
                  <Text className='deflection-section'>
                    <b>{t('support_form_was_answer_helpful', 'Was this answer helpful?')}</b>
                    <Button
                      className='feedback-response-button'
                      variant='contained'
                      onClick={() => onBtnClick(FeedbackResponse.YES)}>
                      {t('support_form_yes', 'Yes')}
                    </Button>
                    <Button
                      className='feedback-response-button'
                      variant='outlined'
                      onClick={() => onBtnClick(FeedbackResponse.NO)}>
                      {t('support_form_no', 'No')}
                    </Button>
                  </Text>
                )}
              </div>
            )}
          </DeflectionContainer>
        ) : (
          <DeflectionContainer>
            <p className='deflection-section'>
              {t('support_form_answer_is_not_helpful', 'Thanks for the feedback; adding additional details will allow us to give a more accurate answer to your inquiry. Use the below “back” Button.')}
            </p>
            <h6 className={`${baseClass}__Case-Name-Spacing`}>
              {t('support_form_suggested_article_alternative',
                'You may also review these suggested resources:')}
            </h6>
            {engine && caseAssistState && (
              <SupportCaseDeflectionModal
                engine={engine}
                responseState={caseAssistState}
                isNewSupportCase
                resultsShownFirst={resultsShownFirst} />
            )}
          </DeflectionContainer>
        )}
    </div>
  );
};

export default DocsGptDeflection;

import type {
  Raw,
  Result,
  ResultListProps,
  ResultListState,
  SearchEngine,
} from '@coveo/headless';
import { buildResultList } from '@coveo/headless';
import { Skeleton } from '@mui/lab';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KB_ASSETS_URL } from '../../../../constants/network.constants';
import {
  DEFAULT_RESULTS_COUNT,
  DOCS_COMBINED_VERSION_NAME,
  DOCS_VERSION_SKIP_TAG_NAME,
  SearchAnalyticsActions,
  SearchResultSource,
  SearchResultSourceLabelMap,
} from '../../../../constants/search.constants';
import { useAuth } from '../../../../contexts/auth';
import downloadDocument from '../../../../lib/customerPortalDownload.utils';
import { StoreContext } from '../../../../store/index';
import {
  logSearchAnalyticsEvent,
  processFacetValue,
} from '../../../../utils/search';
import { FacetFilterTypes } from '../../Facets/types';

const DEFAULT_RESULTS_LIST_PROPS = {
  options: {
    fieldsToInclude: [
      FacetFilterTypes.PRODUCT,
      FacetFilterTypes.VERSION,
      FacetFilterTypes.CONTENT_TYPE,
    ],
  },
};

const Container = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;
`;

const ResultContainer = styled.div`
  padding-bottom: ${p => p.theme.spacing(4)}px;
`;

const ResultLink = styled.a`
  color: ${p => p.theme.palette.blue[500]};
  font-size: 1.6rem;
  font-weight: 700;
`;

const ResultClickableParagraph = styled.p`
  color: ${p => p.theme.palette.blue[500]};
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
`;

const ResultDescription = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: ${p => p.theme.spacing(1.5)}px;
  margin-bottom: ${p => p.theme.spacing(1.5)}px;
`;

const FacetValuesContainer = styled.div`
  display: flex;
  width: 650px;
  overflow: auto;
`;

const FacetText = styled.div`
  position: relative;
  color: ${p => p.theme.palette.grey[600]};
  font-weight: 500;
  font-size: 1.2rem;
  white-space: nowrap;
  padding-right: 20px;
  :not(:last-child)&::after {
    position: absolute;
    top: 40%;
    right: ${p => p.theme.spacing(1.25)}px;
    display: block;
    content: '';
    height: ${p => p.theme.spacing(0.25)}px;
    width: ${p => p.theme.spacing(0.25)}px;
    background: ${p => p.theme.palette.grey[600]};
    border-radius: ${p => p.theme.spacing(0.25)}px;
  }
`;

const SkeletonTitle = styled(Skeleton)`
  && {
    width: 50%;
    height: ${p => p.theme.spacing(4)}px;
  }
`;
const SkeletonBody = styled(Skeleton)`
  && {
    width: 100%;
    height: ${p => p.theme.spacing(6)}px;
  }
`;
const SkeletonLabel = styled(Skeleton)`
  && {
    width: 50%;
    height: ${p => p.theme.spacing(4)}px;
  }
`;

interface Props {
  engine: SearchEngine;
  resultsListProps?: ResultListProps;
}

const ResultList = (props: Props) => {
  const { t } = useTranslation('common');

  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const {
    engine, resultsListProps = DEFAULT_RESULTS_LIST_PROPS,
  } = props;

  const resultsListControllerRef = useRef(
    buildResultList(engine, resultsListProps)
  );
  const [ resultListState, setResultListState ] = useState<ResultListState>(
    resultsListControllerRef.current.state
  );
  const resultContainerRef = useRef<HTMLDivElement | null>(null);

  const setAndSubscribeResultList = useCallback(() => resultsListControllerRef.current.subscribe(() => {
    setResultListState(resultsListControllerRef.current.state);
  }), []);

  useEffect(() => {
    const unsubscribeResultListListener = setAndSubscribeResultList();

    return () => {
      unsubscribeResultListListener?.();
    };
  }, [ setAndSubscribeResultList ]);

  const generateFacetValues = (rawQueryValues: Raw) => {
    const facets: Array<{ facetLabel: string; facetType: string }> = [];
    for (const facetType of [
      FacetFilterTypes.CONTENT_TYPE,
      FacetFilterTypes.PRODUCT,
      FacetFilterTypes.VERSION,
    ]) {
      const facetValue = rawQueryValues[facetType];

      if (Array.isArray(facetValue)) {
        const processedFacetValues: Array<{ facetLabel: string; facetType: string }> =
        facetValue.map((currVal) => ({
          facetLabel: processFacetValue(currVal, facetType, rawQueryValues),
          facetType,
        })).filter(e => e.facetLabel !== DOCS_VERSION_SKIP_TAG_NAME);
        facets.push(...processedFacetValues);
        if (processedFacetValues.length < facetValue.length) {
          facets.push({
            facetLabel: DOCS_COMBINED_VERSION_NAME,
            facetType,
          });
        }
      } else if (typeof facetValue === 'string') {
        facets.push({
          facetLabel: processFacetValue(facetValue, facetType, rawQueryValues),
          facetType,
        });
      }
    }

    return (
      <FacetValuesContainer>
        {facets.map(({
          facetLabel, facetType,
        }, idx) => (
          // For content type labels, apply custom rename and localization
          facetType === FacetFilterTypes.CONTENT_TYPE && SearchResultSourceLabelMap[facetLabel] ?
            <FacetText key={`${facetLabel}-${idx}`}>
              {t(
                SearchResultSourceLabelMap[facetLabel].localText,
                SearchResultSourceLabelMap[facetLabel].fallbackText
              )}
            </FacetText>
            : <FacetText key={`${facetLabel}-${idx}`}>{facetLabel}</FacetText>
        ))}
      </FacetValuesContainer>
    );
  };

  const renderSkeleton = () => {
    const skeletonCount = engine.state.pagination?.numberOfResults || DEFAULT_RESULTS_COUNT;

    return Array.apply(null, Array(skeletonCount)).map((_, idx) => (
      <ResultContainer key={`results-skeleton-${idx}`}>
        <SkeletonTitle variant='text' />
        <SkeletonBody variant='rectangular' />
        <SkeletonLabel variant='text' />
      </ResultContainer>
    ));
  };

  const logClickEvent = (result: Result) => {
    logSearchAnalyticsEvent(engine, SearchAnalyticsActions.DOCUMENT_OPEN, result);
  };

  const handleKBDocFileOnClick = async (fileId: string) => {
    try {
      await downloadDocument(`${KB_ASSETS_URL}/${fileId}`, undefined, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
        },
      });
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `Error going to hyperlink: ${e.response.data.data}`
          : `Error going to hyperlink: ${e.toString()}`;

      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const renderResult = (result: Result & { idx: number }) => {
    const {
      clickUri,
      excerpt,
      raw,
      title,
      uniqueId,
      idx,
    } = result;

    return (
      <ResultContainer
        key={`${uniqueId}-${idx}`}
        ref={resultContainerRef}
        data-testid='ResultsListItemContainer'
      >
        <ResultLink
          href={clickUri}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => logClickEvent(result)}
        >
          {title}
        </ResultLink>
        <ResultDescription>{excerpt}</ResultDescription>
        {generateFacetValues(raw)}
      </ResultContainer>
    );
  };

  const renderKBResult = (result: Result & { idx: number }) => {
    const {
      clickUri,
      excerpt,
      raw,
      title,
      uniqueId,
      idx,
    } = result;

    // Expected clickUri to be something like:
    // "https://customerportal-dev.uipath.com/server/kb_documents/60ef34febed6648696d42b47?hyperlink=https://www.uipath.com"
    const splitted = clickUri.split('kb_documents/');
    if (splitted.length !== 2) {
      return null;
    }
    const idAndhyperlink: string[] = splitted[1].split('?hyperlink=');
    if (idAndhyperlink.length !== 2) {
      return null;
    }
    const [ id, hyperlink ] = idAndhyperlink;

    return (
      <ResultContainer
        key={`${uniqueId}-${idx}`}
        ref={resultContainerRef}
        data-testid='ResultsListItemContainer'
      >
        {hyperlink.length ? (
          <ResultLink
            href={hyperlink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => logClickEvent(result)}
          >
            {title}
          </ResultLink>
        ) : (
          <ResultClickableParagraph
            onClick={() => {
              handleKBDocFileOnClick(id);
              logClickEvent(result);
            }}
          >
            {title}
          </ResultClickableParagraph>
        )}
        <ResultDescription>{excerpt}</ResultDescription>
        {generateFacetValues(raw)}
      </ResultContainer>
    );
  };

  return (
    <Container data-testid='ResultsListContainer'>
      {resultListState.isLoading
        ? renderSkeleton()
        : resultListState?.results.map((result, idx) => (
          result.raw.source === SearchResultSource.KNOWLEDGE_BASE
            ? renderKBResult({
              ...result,
              idx,
            })
            : renderResult({
              ...result,
              idx,
            })
        ))}
    </Container>
  );
};

export default ResultList;

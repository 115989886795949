import { buildCaseInput } from '@coveo/headless/case-assist';
import { DocsGptResponseSource } from '@customer-portal/constants';
import { getCaseRecordType } from '@customer-portal/utils';
// Component
import Button from '@mui/material/Button';
import { lowerCase } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import * as styles from '../../../assets/css/Support/Case';
import {
  axiosPost,
  axiosPublicPost,
} from '../../../client/axios';
import {
  CaseAssistAnalyticsActions,
  ResultsShown,
} from '../../../constants/caseAssist.constants';
import {
  CREATE_SUPPORT_CASE_URL,
  PUBLIC_CREATE_SUPPORT_CASE_URL,
  PUBLIC_SUPPORT_GPT_SEARCH_URL,
  PUBLIC_VALIDATE_USER_EMAIL_DOMAIN_URL,
  SUPPORT_GPT_SEARCH_URL,
  VALIDATE_USER_EMAIL_DOMAIN_URL,
} from '../../../constants/network.constants';
import {
  INVALID_ENTITLEMENT_CASE_CREATION_ERROR,
  INVALID_LICENSE,
  LICENSE_VALIDATION_API_ERROR,
  LICENSE_VALIDATION_WARNING,
  MAX_DESCRIPTION_LENGTH,
  VALID_LICENSE,
  VersionDropdownField,
} from '../../../constants/support.constants';
import { useAuth } from '../../../contexts/auth';
import { DynamicSupportFormField } from '../../../interfaces/sfdc.interface';
// Interface, Constants
import type {
  CaseNavigationButtonProps,
  NewIFormDataKeys,
} from '../../../interfaces/support.interface';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { encodeUTF8ToBase64 } from '../../../lib/encodings.utils';
import { isValidAccentedString } from '../../../lib/index.util';
import { getCaseWebFormRegion } from '../../../lib/support.util';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import { logCaseAssistAnalyticsEvent } from '../../../utils/caseAssist';

const CaseNavigationButton = (props: CaseNavigationButtonProps) => {
  const {
    engine,
    currentPage,
    totalPages,
    setCurrentPage,
    setShowCancelConfirmation,
    formData,
    setFormData,
    isRequiredLicense,
    licenseInfo,
    filteredSupportFormFieldMappings,
    supportIdValidation,
    setSupportIdValidation,
    loadingSupportIdValidation,
    siteUrlValidationError,
    setSiteUrlValidationError,
    attachedFile,
    attachmentForm,
    setAttachmentForm,
    setShowEmailDomainValidationModal,
    setCaseCreated,
    setIsFormSubmitting,
    primaryVersion,
    outOfSupport,
    isCsrfTokenSet,
    setIsCsrfTokenSet,
    isRateLimited,
    setIsRateLimited,
    setGptResponse,
    setIsGptResponseLoading,
    isOutofScopeLanguage,
    setShowCoveoResults,
    setReceivedFeedbackResponse,
    setResultsShownFirst,
    setDoneStreamedText,
  } = props;
  const isPublic = props.isPublic ?? false;
  const { t } = useTranslation('common');
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // isRegistered signifies that the user is both (1) authenticated and (2) registered in Customer Portal DB
  // When isPublic is true, the user's state always has isRegistered as false, but we add the first check to be explicit
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();

  const isUiPathUserWithNoCompanySelected = UserPermissionsHelper.isUiPathUserWithNoCompanySelected();

  const caseSummaryInput = buildCaseInput(props.engine, { options: { field: 'summary' } });
  const caseDescriptionInput = buildCaseInput(props.engine, { options: { field: 'description' } });
  const caseProductInput = buildCaseInput(props.engine, { options: { field: 'product' } });
  const caseErrorMessageInput = buildCaseInput(props.engine, { options: { field: 'errorMessage' } });

  const isNavigationDisabled = isPublic && (!isCsrfTokenSet || isRateLimited);

  const handleFormNext = async (textInputs: string[], selectInputs: string[]) => {
    const newFormData = { ...formData };

    // Add error messages for text inputs
    textInputs.forEach((field: string) => {
      const value = formData[field as NewIFormDataKeys]?.trim();
      if (field === 'supportId') {
        if ((!value || value?.length === 0) && isRequiredLicense && filteredSupportFormFieldMappings) {
          setSupportIdValidation(null);
          newFormData.errors.supportId = t(
            'support_form_error_message_empty_textbox_field',
            'Please fill the {{fieldName}} field!',
            { fieldName: lowerCase(field) }
          );
        }
        return;
      }

      // contact Name field value should only be letters (including accented), hyphens, and spaces
      if (
        field === 'contactName' &&
        value.length > 0 &&
        !isValidAccentedString(value)
      ) {
        newFormData.errors.contactName = t(
          'support_form_error_message_invalid_name_textbox_field'
        );
        return;
      }

      if (field === 'contactEmail' && value.length > 0) {
        if (!validator.isEmail(value)) {
          newFormData.errors.contactEmail = t(
            'support_form_error_message_invalid_email_textbox_field',
            'Please enter a valid email!'
          );
          return;
        }
      }

      if (field === 'description' && value.length >= MAX_DESCRIPTION_LENGTH) {
        newFormData.errors.description = t(
          'support_form_error_message_invalid_desc_textbox_field',
          `Please condense the description field, as it exceeds {{maxDescriptionLength}} characters or more.`,
          { maxDescriptionLength: MAX_DESCRIPTION_LENGTH },
        );
        return;
      }

      if (value?.length === 0) {
        newFormData.errors[
          field
        ] = t(
          'support_form_error_message_empty_textbox_field',
          'Please fill the {{fieldName}} field!',
          { fieldName: lowerCase(field) }
        );
      }

      // Clean up error messages dynamically for any text input fields in DynamicSupportFormField
      if (
        field in DynamicSupportFormField &&
        !filteredSupportFormFieldMappings?.[field as DynamicSupportFormField]?.isRequired
      ) {
        delete newFormData.errors[field];
      }
    });

    const productComponentVersions = formData.product.productComponentVersions;
    const hasAnyProductComponentVersions = !!productComponentVersions?.length;

    // Add error messages for select inputs
    selectInputs.forEach((field: string) => {
      // Don't show error message for product component version if product doesn't have versions
      if (
        field === 'productComponentVersion' &&
        (!hasAnyProductComponentVersions ||
          !filteredSupportFormFieldMappings?.productComponentVersion?.isRequired)
      ) {
        delete newFormData.errors[field];
        return;
      }

      if (formData[field as NewIFormDataKeys] === '' || formData[field as NewIFormDataKeys].value === 'None') {
        newFormData.errors[
          field
        ] = t(
          'support_form_error_message_empty_select_box_field',
          'Please select the {{fieldName}}!',
          { fieldName: lowerCase(field) }
        );
      }

      // Clean up error messages dynamically for any select input fields in DynamicSupportFormField
      if (
        (currentPage === 1 &&
        field in DynamicSupportFormField &&
        !filteredSupportFormFieldMappings?.[field as DynamicSupportFormField]?.isPrimaryVersionPicklist) ||
        (field in DynamicSupportFormField &&
        !filteredSupportFormFieldMappings?.[field as DynamicSupportFormField]?.isRequired) ||
        (field === 'product' && formData.deploymentType.value === 'None')
      ) {
        delete newFormData.errors[field];
      }
    });
    setFormData(newFormData);

    if (Object.keys(formData.errors).length === 0 && !siteUrlValidationError) {
      switch (currentPage) {
        case 1:
          if (supportIdValidation?.textKey !== LICENSE_VALIDATION_API_ERROR.textKey &&
            supportIdValidation?.textKey !== INVALID_ENTITLEMENT_CASE_CREATION_ERROR.textKey) {
            caseSummaryInput.update(formData.summary);
            caseDescriptionInput.update(formData.description);
            caseProductInput.update(formData.product.value);
            caseErrorMessageInput.update(formData.errorMessage);
            props.controller.fetch();
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);

            // get docsGptResponse
            if (!isOutofScopeLanguage) {
              let version = '';
              switch (primaryVersion) {
                case VersionDropdownField.STUDIO_OR_ROBOT_VERSION:
                  version = formData.studioOrRobotVersion.value;
                  break;
                case VersionDropdownField.AUTOMATION_SUITE_VERSION:
                  version = formData.automationSuiteVersion.value;
                  break;
                case VersionDropdownField.ORCHESTRATOR_VERSION:
                  version = formData.orchestratorVersion.value;
                  break;
                case VersionDropdownField.PRODUCT_COMPONENT_VERSION:
                  version = formData.productComponentVersion.value;
                  break;
                default:
                  break;
              }
              try {
                setIsGptResponseLoading(true);
                let docsgptResult;
                const data = {
                  question: formData.description,
                  products: [ formData.product.value ],
                  deliveryOptions: [ formData.deploymentType.value ],
                  versions: [ version ],
                  source: DocsGptResponseSource.CASE_CREATION,
                };
                // TODO: For now, we're using the same endpoint for public and authenticated-but-unregistered users - look into refactoring.
                if (isPublic || !isRegistered) {
                  docsgptResult = await axiosPublicPost(
                    `${PUBLIC_SUPPORT_GPT_SEARCH_URL}`,
                    data,
                  );
                } else {
                  docsgptResult = await axiosPost(
                    SUPPORT_GPT_SEARCH_URL,
                    state.companyId,
                    await getAccessToken(),
                    data
                  );
                }

                if (docsgptResult.status === 200) {
                  setGptResponse(docsgptResult.data);
                  setResultsShownFirst(ResultsShown.DOCSGPT);
                }
              } catch (e) {
                setShowCoveoResults(true);
                setResultsShownFirst(ResultsShown.COVEO);
              }
              setIsGptResponseLoading(false);
            } else {
              setShowCoveoResults(true);
              setResultsShownFirst(ResultsShown.COVEO);
            }
          }
          break;
        case 2:
          setAttachmentForm(document.getElementById('case_attachment') as HTMLFormElement);
          setCurrentPage(currentPage + 1);
          logCaseAssistAnalyticsEvent(
            props.engine,
            CaseAssistAnalyticsActions.CASE_FINAL_PAGE
          );
          window.scrollTo(0, 0);
          break;
        case 3:
          // Validate email domain for public and unregistered users
          if (!isRegistered) {
            let validateUserEmailResult;
            try {
              if (isPublic) {
                validateUserEmailResult = await axiosPublicPost(
                  PUBLIC_VALIDATE_USER_EMAIL_DOMAIN_URL,
                  { email: formData.contactEmail.toLowerCase().trim() },
                );
              } else {
                validateUserEmailResult = await axiosPost(
                  VALIDATE_USER_EMAIL_DOMAIN_URL,
                  '',
                  await getAccessToken(),
                  { email: formData.contactEmail.toLowerCase().trim() },
                );
              }
            } catch (e) {
              if (e.response?.status === 401 && setIsCsrfTokenSet) {
                setIsCsrfTokenSet(false);
                return;
              }
              if (e.response?.status === 429 && setIsRateLimited) {
                setIsRateLimited(true);
                return;
              }
              console.error(e);
              setShowEmailDomainValidationModal(true);
              break;
            }
            const isValid = validateUserEmailResult?.status === 200 && validateUserEmailResult?.data?.isValid;
            if (!isValid) {
              setShowEmailDomainValidationModal(true);
              break;
            }
          }

          // Attempt to submit the case when no form errors and email domain is valid
          handleSubmitCase();
          break;
        default:
          break;
      }
    }
  };

  const handleSubmitCase = async () => {
    const productComponentVersions = formData.product.productComponentVersions;
    const hasAnyProductComponentVersions = !!productComponentVersions?.length;

    const deploymentModels = formData.product.deploymentModels;
    const hasAnyDeploymentModels = !!deploymentModels?.length;

    const packageNames = formData.product.packageNames;
    const hasAnyPackageNames = !!packageNames?.length;

    const fData = attachedFile.isHidden
      ? new FormData()
      : new FormData(attachmentForm);

    const caseRecordType = getCaseRecordType(
      formData.product.value,
      licenseInfo.isTrial,
      state.companyCountry || formData.country.value
    );
    const caseWebFormRegion = getCaseWebFormRegion(
      formData.deploymentType.value,
      formData.product.value
    );
    fData.set(
      'accountId',
      (isRegistered && !isUiPathUserWithNoCompanySelected) ? state.companyId : licenseInfo.accountId
    );
    fData.set('username', state.userName); // For public users, this would be what userName is defined in initialState
    fData.set('contactEmail', formData.contactEmail);
    fData.set('contactName', formData.contactName);
    fData.set('phoneNumber', formData.phoneNumber);
    fData.set('country', formData.country.value);
    fData.set('timezone', formData.timezone.value);
    fData.set('subject', formData.summary);
    fData.set('description', encodeUTF8ToBase64(formData.description));
    fData.set('priority', formData.priority.value);
    fData.set('caseType', caseRecordType);
    fData.set('deploymentType', formData.deploymentType.value);
    fData.set('product', formData.product.value);
    fData.set('subscriptionCode', licenseInfo.subscriptionCode);
    fData.set('subscriptionType', licenseInfo.subscriptionType);
    fData.set('licenseEndDate', licenseInfo.endDate);
    fData.set('caseWebFormRegion', caseWebFormRegion);
    if (
      supportIdValidation?.textKey === VALID_LICENSE.textKey ||
      supportIdValidation?.textKey === LICENSE_VALIDATION_WARNING.textKey) {
      fData.set('supportId', formData.supportId);
    }

    if (formData.errorMessage.length > 0) {
      fData.set('errorMessage', encodeUTF8ToBase64(formData.errorMessage));
    }

    // Dynamically add dynamic support form field data to the payload
    for (const field of Object.keys(filteredSupportFormFieldMappings ?? {})) {
      if (field in DynamicSupportFormField && field in formData) {
        let payloadFieldName: string = field;
        let doesPassExtraCheck: boolean = true;

        // Handle all non-dropdown dynamic fields
        if (
          typeof formData[field as NewIFormDataKeys] !== 'object' ||
          !('value' in formData[field as NewIFormDataKeys])
        ) {
          if (doesPassExtraCheck) {
            fData.set(payloadFieldName, formData[field as NewIFormDataKeys]);
          }
          continue;
        }

        // If dropdown field is None, then don't include it to the payload
        if (formData[field as NewIFormDataKeys].value === 'None') {
          continue;
        }

        // Handle specific checks for dropdown fields
        switch (field as keyof typeof DynamicSupportFormField) {
          case 'productComponentVersion':
            doesPassExtraCheck = hasAnyProductComponentVersions;
            break;
          case 'deploymentModel':
            doesPassExtraCheck = hasAnyDeploymentModels;
            break;
          case 'studioOrRobotVersion':
            payloadFieldName = 'studioVersion';
            break;
          case 'packageName':
            doesPassExtraCheck = hasAnyPackageNames;
            break;
          default:
            break;
        }

        if (doesPassExtraCheck) {
          const selectedValue = formData[field as NewIFormDataKeys].value;
          fData.set(payloadFieldName, selectedValue);
        }
      }
    }

    const { companyCountry } = state;
    if (companyCountry !== 'Japan' && outOfSupport) {
      fData.set('outOfSupport', 'Out of Support Ticket');
    }

    setIsFormSubmitting(true);
    triggerTrackEventWithStateData('Support.Submit', {
      Type: 'Incident',
      isPublic,
      isRegistered,
    });
    try {
      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_form_create_case_loading',
          'Creating a new case. This may take a few seconds...'
        ),
      });

      let result;
      if (isPublic) {
        result = await axiosPublicPost(
          `${PUBLIC_CREATE_SUPPORT_CASE_URL}`,
          fData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
      } else {
        result = await axiosPost(
          `${CREATE_SUPPORT_CASE_URL}`,
          isRegistered ? state.companyId : '',
          await getAccessToken(),
          fData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
      }

      if (result?.status === 201) {
        setCaseCreated(result?.data?.case);
      }
    } catch (e) {
      if (e.response?.status === 401 && setIsCsrfTokenSet) {
        setIsFormSubmitting(false);
        setIsCsrfTokenSet(false);
        return;
      }
      if (e.response?.status === 429 && setIsRateLimited) {
        setIsFormSubmitting(false);
        setIsRateLimited(true);
        return;
      }
      let errorMessage = t(
        'support_form_create_case_error_without_error_response_msg',
        'Error while creating the case'
      );
      if (
        e.response?.data?.message
          ?.toLowerCase()
          .includes('unable to retrieve account info')
      ) {
        errorMessage = t(INVALID_LICENSE.textKey, INVALID_LICENSE.fallbackText);
      }

      setIsFormSubmitting(false);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const onCancel = () => {
    setShowCancelConfirmation(true);
  };

  const onBack = () => {
    const newFormData = { ...formData };
    newFormData.errors = {};
    setFormData(newFormData);
    setSiteUrlValidationError(null);
    setCurrentPage(currentPage - 1);
    setShowCoveoResults(false);
    setReceivedFeedbackResponse(false);
    setDoneStreamedText(false);
    window.scrollTo(0, 0);
  };

  const onNext = () => {
    let textInputs: string[] = [];
    let selectInputs: string[] = [];
    switch (currentPage) {
      case 1:
        textInputs = [ 'summary', 'description', 'supportId' ];
        selectInputs = [
          'deploymentType',
          'product',
          'studioOrRobotVersion',
          'orchestratorVersion',
          'productComponentVersion',
          'automationSuiteVersion',
        ];
        break;
      case 2:
        textInputs = [ 'siteUrl', 'packageVersion' ];
        selectInputs = [
          'deploymentModel',
          'environment',
          'priority',
          'studioOrRobotVersion',
          'orchestratorVersion',
          'airgapped',
          'architecture',
          'packageName',
          'issueType',
        ];
        break;
      case 3:
        textInputs = [ 'contactName', 'contactEmail', 'phoneNumber' ];
        selectInputs = [ 'country', 'timezone' ];
        break;
      default:
        break;
    }
    handleFormNext(textInputs, selectInputs);
  };

  return (
    <styles.navigationButtons data-testid='navigation-buttons'>
      <Button
        onClick={onCancel}
        className='cancelBtn'
        data-testid='cancel-button'
      >
        {t('support_incident_cases_cancel', 'Cancel')}
      </Button>
      <Button
        variant='outlined'
        onClick={onBack}
        disabled={currentPage === 1 || isNavigationDisabled}
        className='backBtn'
        data-testid='back-button'
      >
        {t('support_incident_cases_back', 'Back')}
      </Button>
      {
        currentPage === totalPages ? (
          <Button
            variant='contained'
            color='secondary'
            onClick={onNext}
            className='submitBtn'
            data-testid='submit-button'
            disabled={isNavigationDisabled}
          >
            {t('support_incident_cases_create_case', 'Create Case')}
          </Button>
        ) : (
          <Button
            variant='contained'
            color='secondary'
            disabled={loadingSupportIdValidation || isNavigationDisabled}
            onClick={onNext}
            className='nextBtn'
            data-testid='next-button'
          >
            {t('support_incident_cases_next', 'Next')}
          </Button>
        )
      }
    </styles.navigationButtons>
  );
};

export default CaseNavigationButton;
